import React, {SyntheticEvent, useState} from 'react';
import './App.css';
import RANTable from "./components/RANTable";
import Tracing from "./components/Tracing";

const App = () => {
    const [characters, setCharacters] = useState<string[]>(['A','B','C','D','E']);
    const [inputLength, setInputLength] = useState<number>(5);

    const handleInputChanged = (e: SyntheticEvent<HTMLInputElement>) => {
        const input: string = (e.currentTarget.value as string)?.trim() ?? '';
        setInputLength(input.length);
        if (input.length === 5) setCharacters([...input]);
    };

    const handleShuffle = () => {
        setCharacters(prev => prev.map((character) => character));
    };

    const handlePrint = () => {
        window.print();
    };

  return (
    <div className="App">
      <header className="App-header">
        <h2>RAN Generator</h2>
      </header>
      <main>
          <div className="inputs">
              <div className="formRow">
                  <label>Characters:</label>
                  <input type='text' defaultValue="ABCDE" maxLength={5} onChange={handleInputChanged} />
              </div>
              <span className={inputLength === 5 ? 'hidden' : undefined}>Character input length must be 5</span>
              <div className="formRow">
                  <button onClick={handleShuffle}>Shuffle</button>
                  <button onClick={handlePrint}>Print</button>
              </div>
          </div>
          <RANTable characters={characters} />
          <Tracing characters={characters} count={8} />
      </main>
    </div>
  );
}

export default App;
