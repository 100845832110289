import {shuffle} from "../utils/shuffle";
import React from "react";

type RANTableProps = {
    characters: string[]
}

type TableRowProps = Pick<RANTableProps, 'characters'>;

const TableRow = ({characters} : TableRowProps) => (
    <tr>
        { characters.map((character, index) => <td key={index}>{character}</td>)}
    </tr>
    );

const RANTable = ({characters} : RANTableProps) => {
    if (characters.length !== 5) return <p>Passed in characters can only be of length 4.</p>

    return (
    <table className="ranTable">
        <tbody>
        <TableRow characters={characters} />
        { [2, 3, 4, 5].map((row) => <TableRow characters={shuffle(characters)} key={row} /> )}
        </tbody>
    </table>
    );
};

const MemoizedRanTable = React.memo(RANTable);

export default MemoizedRanTable;
