type TracingProps = {
    characters: string[],
    count: number
}

type TracingRowProps = {
    character: string;
    count: number;
}

const TracingRow = ({character, count}: TracingRowProps) => {
    const items = Array.from({length: count}, () => character)

    return <div className="tracingRow">
        <hr />
        {items.map((item, index) => <span key={index}>{item}</span>)}
    </div>;
};

const Tracing = ({characters, count} : TracingProps) => {
    return (
        <div className="tracingList">
            {characters.map((character, index) => <div key={index}>
                <TracingRow count={count} character={character} />
                <TracingRow count={count} character="" />
            </div>)}
        </div>
    );
};

export default Tracing;
